import React, { useMemo, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { Deal, FieldConfig, MediaItem } from '../interfaces';
import { getMediaUrl } from '../utils/media/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { useToast } from '../services/context/ToastContext';
import WvStatusIndicator from './WvStatusIndicator';

interface MediaViewerModalProps {
    show: boolean;
    handleClose: () => void;
    media: MediaItem[];
    title?: string;
    onSubmitSuccess: () => void;
    fieldConfigs?: FieldConfig[];
    deal?: Deal;
}

/**
 * WvViewerModal Component
 *
 * This component displays a modal that serves as a viewer for documents or media items,
 * such as the "Weiterbildungsvertrag" document. It provides options to view, download,
 * or check the status of the document.
 *
 * Props:
 * - show (boolean): Controls the visibility of the modal.
 * - handleClose (function): Callback to close the modal.
 * - media (MediaItem[]): Array of media items associated with the deal, used to locate specific documents.
 * - title (string, optional): Title of the modal.
 * - onSubmitSuccess (function): Callback function triggered after successful submission or action.
 * - fieldConfigs (FieldConfig[], optional): Array of field configurations, allowing dynamic labels and options.
 * - deal (Deal, optional): Deal object containing relevant deal data, such as status.
 */
const WvViewerModal: React.FC<MediaViewerModalProps> = ({
    show,
    handleClose,
    media,
    title,
    onSubmitSuccess,
    fieldConfigs,
    deal
}) => {

    const [isLoading, setIsLoading] = useState<boolean>()
    const { showToast } = useToast();

    const wv = useMemo(() => {
        return media.find(item => item.title === 'wv.pdf') ?? null;
    }, [media]);

    return (
        <Modal show={show} onHide={handleClose} fullscreen>
            <Modal.Header className="d-flex flex-column flex-lg-row align-items-start justify-content-lg-between align-items-lg-center">
                <div className='d-flex flex-column flex-lg-row'>
                    <div className='d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-3 mb-lg-0' style={{ minWidth: '250px' }}>
                        <Button
                            variant="secondary"
                            className="btn-soft-primary round-modal-close-button me-2 mb-3 mb-lg-0"
                            style={{ padding: 0 }}
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faX} size='sm' />
                        </Button>

                        <div><h5 className='mb-0'>{title}</h5>
                            <a href={'https://www.pandadoc.com'} target="_blank" rel="noopener noreferrer" style={{ cursor: 'text' }}>
                                <p className={`p-0 m-0 text-gray`}>Pandadoc-ID: 123456789</p>
                            </a>
                        </div>
                    </div>

                    <div className='vertical-line d-none d-lg-block'></div>


                    <div className='ps-lg-5 mb-3 mb-lg-0'>
                        <div className='mb-0'>
                            <WvStatusIndicator fieldConfigs={fieldConfigs ?? []} wvStatus={deal?.wvStatus ?? 0} />
                        </div>
                        <p className={`p-0 m-0 text-gray`}>{wv ? '01.01.1990' : "Noch nicht vorhanden"}</p>
                    </div>
                </div>
                <div>
                    {wv && (
                        <>
                            <a href={`/${localStorage.getItem('companyId') || 'oc'}/media/${wv.folderId}/${wv.id}`} target="_blank" rel="noopener noreferrer">
                                <Button
                                    variant="secondary"
                                    className="btn-soft-primary me-2"
                                >
                                    Zum Medium <FontAwesomeIcon icon={faUpload} size='sm' />
                                </Button>
                            </a>
                        </>
                    )}
                </div>
            </Modal.Header>
            <Modal.Body className="py-0">
                <Row className='h-100'>
                    <Col className='d-flex flex-column h-100 p-0' style={{ zIndex: 10 }}>
                        <div className="media-preview bg-grey flex-grow-1 d-flex justify-content-center align-items-center">
                            {wv?.mediaType === 'image' && (
                                <img src={getMediaUrl(wv)} alt={wv.title} className="center-image img-fluid" />
                            )}
                            {wv?.extension === 'pdf' && (
                                <iframe
                                    src={getMediaUrl(wv)}
                                    title={wv.title}
                                    className='h-100 w-100 m-0 p-0'
                                    style={{ border: 'none' }}
                                ></iframe>
                            )}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default WvViewerModal;
