import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileDropzoneProps {
    onFilesChange: (files: File[]) => void; // Callback to pass files to the parent
    isDisabled?: boolean;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({ onFilesChange, isDisabled }) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        onFilesChange(acceptedFiles);
    }, [onFilesChange]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: 50 * 1024 * 1024, // Set maximum file size to 50MB
        disabled: isDisabled,
    });

    return (
        <div {...getRootProps()} className="p-5 text-center rounded" style={{ border: '2px dashed #EEEEEE' }}>
            <input {...getInputProps()} />
            <div className='d-flex justify-content-center mb-4'> <FontAwesomeIcon size="2xl" icon={faUpload} /></div>

            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Dateien hier ablegen oder zum <span className='text-decoration-underline'>Hochladen</span> anklicken
                        Maximale Dateigröße: 50MB</p>
            }
        </div>
    );
};

export default FileDropzone;
