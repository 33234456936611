import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalWrapper from './PortalWrapper';
import { FieldConfig } from '../interfaces';
import { bgsStatusColorMap, bgsStatusIconMap } from './filter/iconAndColorMappings';
import { getFieldConfigByResourceName } from '../utils/utils';

interface BgsStatusDropdownButtonProps {
    statusBGS: number;
    handleBgsStatusUpdate: (status: number) => void;
    fieldConfigs?: FieldConfig[];
}

/**
 * BgsStatusDropdownButton Component
 *
 * This component renders a dropdown button that allows the user to view and update
 * the status of a "Bildungsgutschein" (BGS) within a deal. The button displays the
 * current status with a corresponding icon and color, and it provides other statuses
 * as dropdown options for easy status updates.
 *
 * Props:
 * - statusBGS (number): Current status code for the Bildungsgutschein.
 * - handleBgsStatusUpdate (function): Callback to update the status when a new status
 *   is selected from the dropdown.
 * - fieldConfigs (FieldConfig[], optional): Array of field configurations, providing
 *   options for the dropdown based on deal field settings.
 */
const BgsStatusDropdownButton: React.FC<BgsStatusDropdownButtonProps> = ({
    statusBGS,
    handleBgsStatusUpdate,
    fieldConfigs
}) => {
    const bgsStatusFieldConfig: FieldConfig | {} = getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus') ?? {};
    const options = (bgsStatusFieldConfig as FieldConfig).options ?? {};
    
    return (
        <Dropdown>
            <Dropdown.Toggle
                as={Button}
                variant="secondary"
                id="dropdown-basic"
                className={`btn-soft-primary w-100 ${bgsStatusColorMap[statusBGS]}`}
            >
                <span className="d-flex align-items-center">
                    {<FontAwesomeIcon icon={bgsStatusIconMap[statusBGS]} className="me-2" />}
                    {options[statusBGS] || 'Status'}
                </span>
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(options)
                        .filter(([statusKey]) => Number(statusKey) !== statusBGS)
                        .map(([statusKey, statusValue]) => (
                            <Dropdown.Item
                                key={statusKey}
                                onClick={() => handleBgsStatusUpdate(Number(statusKey))}
                                className="d-flex align-items-center"
                            >
                                <FontAwesomeIcon
                                    width={15}
                                    icon={bgsStatusIconMap[Number(statusKey)]}
                                    className={`me-2 ${bgsStatusColorMap[Number(statusKey)]}`}
                                />
                                {statusValue as string}
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default BgsStatusDropdownButton;
